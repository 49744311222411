<template>
  <div class="offerCreate">
    <v-card class="mx-auto">
      <v-img class="white--text align-end" height="200px" :src="formData.logo">
        <v-card-title class="text--primary pb-0">{{
          formData.title
        }}</v-card-title>
      </v-img>

      <v-form ref="form" @submit="onSubmitForm">
        <v-card-text class="text--primary">
          <v-text-field
            label="Название"
            v-model="formData.title"
            name="title"
            :error-messages="errors.title"
          ></v-text-field>
          <v-file-input
            label="Лого"
            filled
            prepend-icon="mdi-camera"
            placeholder="Выбрать файл"
            v-model="selectFile"
            name="logo"
            :error-messages="errors.logo"
          ></v-file-input>
          <v-text-field
            label="Партнер"
            name="partner"
            v-model="formData.partner"
            :error-messages="errors.partner"
          ></v-text-field>
          <v-text-field
            label="Заголовок"
            name="header"
            v-model="formData.header"
            :error-messages="errors.partner"
          ></v-text-field>
          <v-text-field
            label="Подзаголовок"
            name="sub_header"
            v-model="formData.sub_header"
            :error-messages="errors.sub_header"
          ></v-text-field>
          <v-text-field
            label="Ссылка"
            name="link"
            v-model="formData.link"
            :error-messages="errors.link"
          ></v-text-field>
          <v-slider
            label="Вероятность одобрения"
            v-model="formData.chance"
            min="1"
            max="99"
          >
            <template v-slot:append>
              <v-text-field
                v-model="formData.chance"
                name="chance"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                disabled
                style="width: 60px"
                :error-messages="errors.chance"
              ></v-text-field>
            </template>
          </v-slider>
          <v-card>
            <v-subheader> Правила запроса </v-subheader>
            <v-list>
              <v-list-item
                v-for="(item, i) in formData.query_rules"
                :key="i + '-' + (formData.query_rules || []).length"
              >
                <v-list-item-content>
                  <v-text-field
                    label="название"
                    :name="'query_rules[' + i + '][name]'"
                    v-model="item['name']"
                  ></v-text-field>
                  <v-select
                    label="тип"
                    :items="['REQUEST', 'VALUE']"
                    :name="'query_rules[' + i + '][type]'"
                    v-model="item['type']"
                  ></v-select>
                  <v-text-field
                    label="значение"
                    :name="'query_rules[' + i + '][value]'"
                    v-model="item['value']"
                  ></v-text-field>
                  <v-text-field
                    label="по умолчанию"
                    :name="'query_rules[' + i + '][default]'"
                    v-model="item['default']"
                  ></v-text-field>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="onDelQueryRules(i)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-btn @click="onAddQueryRules" color="primary" text>
                Добавить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn color="orange" :to="offersRoute" text> Отмена </v-btn>
          <v-btn text type="submit">
            {{ isCreate ? "Создать" : "Сохранить" }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("offers");

export default {
  name: "offerCreate",
  data() {
    return {
      errors: {},
      formData: {},
      selectFile: null,
      offersRoute: { name: "offers" },
    };
  },
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.formData = { ...this.$route.meta.formData };
  },
  watch: {
    selectFile: {
      handler: function (selectFile) {
        this.formData.logo = URL.createObjectURL(selectFile);
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    ...mapActions(["create", "update"]),
    onAddQueryRules() {
      this.$set(this.formData, "query_rules", [
        ...(this.formData.query_rules || []),
        {
          name: "",
          type: "REQUEST",
          value: "",
          default: "",
        },
      ]);
    },
    onDelQueryRules(i) {
      this.formData.query_rules.splice(i, 1);
    },
    onSubmitForm(e) {
      e.preventDefault();
      const formElem = this.$refs.form.$el;
      const formData = new FormData(formElem);
      formData.append("chance", this.formData.chance || 0);
      this[this.isCreate ? "_offerCreate" : "_offerUpdate"](formData)
        .then(() => {
          this.$router.push(this.offersRoute);
        })
        .catch((e) => {
          this.errors = Object.keys(e.errors || []).reduce((acc, key) => {
            acc[key] = e.errors[key][0];
            if (acc[key] === "validation.required") {
              acc[key] = "Поле обязательное";
            }
            return acc;
          }, {});
        });
    },
    _offerUpdate(formData) {
      formData.append("_method", "PATCH");

      return this.update({ id: this.$route.params.id, formData });
    },
    _offerCreate(formData) {
      return this.create(formData);
    },
  },
};
</script>
